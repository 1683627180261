export const FONT_FALLBACKS = {
	CustomFont1: 'Impact, sans-serif',
	CustomFont2: 'Arial, sans-serif',
	CustomFont3: 'Verdana, sans-serif',
	CustomFont4: 'Helvetica, sans-serif',
	CustomFont5: 'Times New Roman, serif'
};

const PRESET_RANGES = {
  scanSpeed: { min: 5, max: 40 },
  beamIntensity: { min: 0.035, max: 0.1 },
  beamAttenuation: { min: 0.001, max: 0.025 },
  textAttenuation: { min: 0.5, max: 1.2 },
  fontSize: { min: 1.8, max: 3.2 },
  aberrationOffset: { min: 0.002, max: 0.01 },
  aberrationIntensity: { min: 0.4, max: 1 },
  scanLineDensity: { min: 0.001, max: 0.02 },
  lineHeight: { min: 0.002, max: 0.25 },
};

export const createRandomPreset = () => {
	const presetKeys = Object.keys(PRESETS);
	const randomBaseName = presetKeys[Math.floor(Math.random() * presetKeys.length)];
	const basePreset = PRESETS[randomBaseName];
	const randomizeInRange = (min, max) => Math.random() * (max - min) + min;

	return {
		name: `random_${randomBaseName}`,
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: randomizeInRange(PRESET_RANGES.scanSpeed.min, PRESET_RANGES.scanSpeed.max),
		beamIntensity: randomizeInRange(PRESET_RANGES.beamIntensity.min, PRESET_RANGES.beamIntensity.max),
		beamAttenuation: randomizeInRange(PRESET_RANGES.beamAttenuation.min, PRESET_RANGES.beamAttenuation.max),
		textAttenuation: randomizeInRange(PRESET_RANGES.textAttenuation.min, PRESET_RANGES.textAttenuation.max),
		fontSize: randomizeInRange(PRESET_RANGES.fontSize.min, PRESET_RANGES.fontSize.max),
		aberrationOffset: randomizeInRange(PRESET_RANGES.aberrationOffset.min, PRESET_RANGES.aberrationOffset.max),
		aberrationIntensity: randomizeInRange(PRESET_RANGES.aberrationIntensity.min, PRESET_RANGES.aberrationIntensity.max),
		scanLineDensity: randomizeInRange(PRESET_RANGES.scanLineDensity.min, PRESET_RANGES.scanLineDensity.max),
		lineHeight: randomizeInRange(PRESET_RANGES.lineHeight.min, PRESET_RANGES.lineHeight.max),
		textColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
		useClassicAberration: Math.random() < 0.2,
		useSpectralAberration: Math.random() < 0.65,
		fontFamily: basePreset.fontFamily,
		fallbackFont: basePreset.fallbackFont,
		fontWeight: 'bold'
	};
};

export const PRESETS = {
	classic: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 15,
		beamIntensity: 0.05,
		beamAttenuation: 0.01,
		textAttenuation: 0.7,
		fontSize: 3.2,
		aberrationOffset: 0.002,
		aberrationIntensity: 1,
		scanLineDensity: 0.003,
		lineHeight: 0.04,
		textColor: '#aaccff',
		useClassicAberration: false,
		useSpectralAberration: false,
		fontFamily: 'CustomFont2',
		fallbackFont: FONT_FALLBACKS.CustomFont2,
		fontWeight: 'bold'
	},
	neon: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 20,
		beamIntensity: 0.08,
		beamAttenuation: 0.015,
		textAttenuation: 0.8,
		fontSize: 3,
		aberrationOffset: 0.015,
		aberrationIntensity: 0.6,
		scanLineDensity: 0.012,
		lineHeight: 0.025,
		textColor: '#00ff99',
		useClassicAberration: false,
		useSpectralAberration: true,
		fontFamily: 'CustomFont3',
		fallbackFont: FONT_FALLBACKS.CustomFont3,
		fontWeight: 'bold'
	},
	retro: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 15,
		beamIntensity: 0.05,
		beamAttenuation: 0.02,
		textAttenuation: 0.5,
		fontSize: 3.2,
		aberrationOffset: 0.008,
		aberrationIntensity: 0.4,
		scanLineDensity: 0.008,
		lineHeight: 0.015,
		textColor: '#ffcc00',
		useClassicAberration: true,
		useSpectralAberration: true,
		fontFamily: 'CustomFont3',
		fallbackFont: FONT_FALLBACKS.CustomFont3,
		fontWeight: 'bold'
	},
	artdeco: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 150,
		beamIntensity: 0.06,
		beamAttenuation: 0.025,
		textAttenuation: 0.5,
		fontSize: 3.2,
		aberrationOffset: 0.006,
		aberrationIntensity: 0.5,
		scanLineDensity: 0.003,
		lineHeight: 0.005,
		textColor: '#ffdd00',
		useClassicAberration: false,
		useSpectralAberration: true,
		fontFamily: 'CustomFont1',
		fallbackFont: FONT_FALLBACKS.CustomFont1,
		fontWeight: 'bold'
	},
	crimson: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 5,
		beamIntensity: 0.08,
		beamAttenuation: 0.015,
		textAttenuation: 0.8,
		fontSize: 2,
		aberrationOffset: 0.015,
		aberrationIntensity: 0.6,
		scanLineDensity: 0.012,
		lineHeight: 0.05,
		textColor: '#FF0000',
		useClassicAberration: false,
		useSpectralAberration: true,
		fontFamily: 'CustomFont4',
		fallbackFont: FONT_FALLBACKS.CustomFont4,
		fontWeight: 'bold'
	},
	serif: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 20,
		beamIntensity: 0.05,
		beamAttenuation: 0.01,
		textAttenuation: 1,
		fontSize: 2.5,
		aberrationOffset: 0.02,
		aberrationIntensity: 0.5,
		scanLineDensity: 0.001,
		lineHeight: 0.02,
		textColor: '#FFffff',
		useClassicAberration: false,
		useSpectralAberration: true,
		fontFamily: 'CustomFont5',
		fallbackFont: FONT_FALLBACKS.CustomFont5,
		fontWeight: 'bold'
	},
	slow: {
		text: 'REALITY\nREPROJECTOR',
		scanSpeed: 4,
		beamIntensity: 0.1,
		beamAttenuation: 0.001,
		textAttenuation: 1.2,
		fontSize: 2,
		aberrationOffset: 0.01,
		aberrationIntensity: 0.5,
		scanLineDensity: 0.008,
		lineHeight: 0.15,
		textColor: '#ff0066',
		useClassicAberration: false,
		useSpectralAberration: true,
		fontFamily: 'CustomFont4',
		fallbackFont: FONT_FALLBACKS.CustomFont4,
		fontWeight: 'bold'
	}
};

// Utility functions
export const selectRandomPreset = () => {
	if (Math.random() < 0.7) {
		return createRandomPreset();
	}

	const presetKeys = Object.keys(PRESETS);
	const randomKey = presetKeys[Math.floor(Math.random() * presetKeys.length)];
	return { ...PRESETS[randomKey], name: randomKey };
};

export const getPresetByName = (name) => {
	return { ...PRESETS[name], name };
};

export const getNextPreset = (currentPresetName) => {
	const presetKeys = Object.keys(PRESETS);
	const currentIndex = presetKeys.indexOf(currentPresetName);
	const nextIndex = (currentIndex + 1) % presetKeys.length;
	return { ...PRESETS[presetKeys[nextIndex]], name: presetKeys[nextIndex] };
};

